/* eslint-disable multiline-ternary */
import _ from 'lodash';
import dynamic from 'next/dynamic';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { convertArrPath, timestamp } from 'src/utils/helper';

import { initialLists, initialNews } from 'src/services/member-auth/redux';
import { RiEditFill } from 'react-icons/ri';

import { convertFavorite, convertFollowing, decrementItem, filterFollowing, incrementItem } from 'src/services/member-auth/utils/helper';
import { sendSubscribe } from 'src/services/member-auth/utils';
import { GET } from 'src/services';
import { TbNews } from 'react-icons/tb';
import { DATALAYER_TYPE, sendDataLayerGlobal } from 'src/utils/datalayer/send-datalayer';
import { useRouter } from 'next/router';
import { SHOW_MEMBER } from '../constants';
import { SHOW_COMMEMORATE, WEB_NAME } from 'src/constants';
import { useDispatch, useSelector } from 'react-redux';
const CardV = dynamic(import('src/components/cards/card-v'));
const CarouselsLine = dynamic(import('src/services/member-auth/components/carousels-line'));
const CardFollowing = dynamic(import('src/services/member-auth/components/card-following'));
const CardSubscribe = dynamic(import('src/services/member-auth/components/card-subscribe'));
const CardHSubscribe = dynamic(import('src/services/member-auth/components/card-h-subscribe'));

const SectionMember = ({ data }) => {
  if (SHOW_MEMBER !== 'true') return null;

  if (_.isEmpty(data)) return null;
  const router = useRouter();
  const { user, tags, menu, articlesTags, articlesCategories } = data;
  const dispatch = useDispatch();
  const firstChildRightRef = useRef(null);
  const [rightHeight, setRightHeight] = useState(250);
  const [loading, setLoading] = useState(false);

  // redux
  const selected = useSelector(state => state?.member);
  const [selectedTag, setSelectedTag] = useState(!_.isEmpty(user?.favoriteTags) ? user?.favoriteTags.filter(obj => obj.site === WEB_NAME)[0]?.tags : []); /// **** */
  useEffect(() => {
    setSelectedTag(selected?.tags);
  }, [selected?.tags]);
  useEffect(() => {
    (async () => {
      try {
        /* ------------------------------- CATEGORIES ------------------------------- */
        if (!_.isEmpty(selected?.categories)) {
          const _categories = convertFavorite(selected?.categories, 'nameEng') || [];
          const uriCategories = `/api/v1.0/categories/mapping/categories${convertArrPath(_categories, 'name')}&limit=10`;
          const resData = await GET(uriCategories, false, true);
          if (!_.isEmpty(resData?.data)) {
            const data = resData?.data || [];
            dispatch(initialNews({ type: 'articlesCategories', data }));
          }
        }
      } catch (error) {
        if (error instanceof Error) console.error(`${timestamp()} ==========> Fetch Categories ERROR : `, error.message);
      }
    })();
  }, [selected?.categories]);
  useEffect(() => {
    (async () => {
      try {
        /* ------------------------------- TAGS ------------------------------- */
        if (!_.isEmpty(selected?.tags)) {
          const _tags = selected?.tags ? convertFavorite(selected?.tags, 'nameTh') : [];
          const uriTags = `/api/search/tag${convertArrPath(_tags, 'name')}&limit=10`;

          const resData = await GET(uriTags, false, true);
          if (!_.isEmpty(resData?.data)) {
            const data = resData?.data || [];
            dispatch(initialNews({ type: 'articlesTags', data }));
          }
        }
      } catch (error) {
        if (error instanceof Error) console.error(`${timestamp()} ==========> Fetch tags ERROR : `, error.message);
      }
    })();
  }, [selected?.tags]);
  const showMenu = _.isEmpty(articlesCategories) && _.isEmpty(selected?.articlesCategories);
  const showTags = _.isEmpty(articlesTags) && _.isEmpty(selected?.articlesTags);
  const leftData = _.isEmpty(selected?.articlesTags) ? articlesTags : selected.articlesTags;
  const rightData = _.isEmpty(selected?.articlesCategories) ? articlesCategories : selected.articlesCategories;

  useEffect(() => {
    const getRightHeight = () => {
      const displayQty = 3;
      const itemHeight = firstChildRightRef.current?.offsetHeight || 0;
      const res = _.min([_.max([itemHeight, 80]), 110]) + 15;
      setRightHeight(res * displayQty);
    };
    getRightHeight();
    window.addEventListener('resize', getRightHeight);
    return () => {
      console.log('unmounted');
      window.removeEventListener('resize', getRightHeight);
    };
  }, []);
  /* --------------------------------- HandlerFollow --------------------------------- */
  async function handlerFollow (type, data) {
    try {
      if (!_.isEmpty(user)) {
        if (filterFollowing(selectedTag || [], data)) {
          const dataItem = decrementItem(selectedTag || [], data);
          const response = await sendSubscribe(
            user,
            { tags: dataItem || [], categories: _.isEmpty(selected.categories) ? user.favoriteCategories.filter(obj => obj.site === WEB_NAME)[0]?.categories || [] : selected.categories },
            user?.favoriteCategories,
            user?.favoriteTags
          );
          if (response) {
            dispatch(initialLists({ type: 'tags', data: response?.favoriteTags }));
          }
        } else {
          const dataItem = incrementItem(selectedTag || [], data);

          const response = await sendSubscribe(
            user,
            { tags: dataItem || [], categories: _.isEmpty(selected.categories) ? user.favoriteCategories.filter(obj => obj.site === WEB_NAME)[0]?.categories || [] : selected.categories },
            user?.favoriteCategories,
            user?.favoriteTags
          );
          if (!_.isEmpty(response)) {
            dispatch(initialLists({ type: 'tags', data: response?.favoriteTags }));
            if (!_.isEmpty(response?.favoriteTags)) {
              const _tags = response?.favoriteTags ? convertFavorite(response?.favoriteTags, 'nameTh') : [];
              const uriTags = `/api/search/tag${convertArrPath(_tags, 'name')}&limit=10`;
              const resSubscribe = await GET(uriTags, false, true);

              if (!_.isEmpty(resSubscribe?.data)) {
                const data = resSubscribe?.data || [];
                dispatch(initialNews({ type: 'articlesTags', data }));
              }
            } else {
              setLoading(true); //
            }
          }
        }
      }
    } catch (error) {
      if (error instanceof Error) console.error(`${timestamp()} ==========>  handlerFollow ERROR : `, error.message);
    }
  }
  if (_.isEmpty(tags) && _.isEmpty(leftData) && _.isEmpty(menu) && _.isEmpty(rightData)) return null;
  return (
    <>
      <SectionWrapper id='section-member' rightHeight={rightHeight} style={{ filter: SHOW_COMMEMORATE === 'true' ? 'grayscale(1)' : 'unset' }}>
        <div className='container mem'>
          <HeaderWrapper className='heading'>
            <TbNews size={28} />
            <p> ข่าวที่คุณกำลังติดตาม </p>
            <a
              className='icon-tool'
              href='/member/following'
              onClick={() =>
                sendDataLayerGlobal({
                  type: DATALAYER_TYPE.TRACK_POSITION,
                  router: router.pathname,
                  section: 'ข่าวที่คุณกำลังติดตาม',
                  position: 'ใต้:section-2',
                  data: {
                    heading: 'แก้ไข',
                    title: 'แก้ไขการติดตาม'
                  }
                })
              }
            >
              <span> แก้ไข </span>
              <RiEditFill size={28} />
            </a>
          </HeaderWrapper>
          <div className='member-wrapper'>
            <div className='left-wrapper'>
              {showTags ? (
                _.isEmpty(tags) ? (
                  ''
                ) : (
                  <CarouselsLine infinite={false} mobileSlideShow={2} slideShow={3}>
                    {_.map(tags, (item, index) => {
                      return (
                        <div key={index} className='follow-item'>
                          <CardFollowing
                            type='tags'
                            data={item}
                            handlerFollow={(type, data) => handlerFollow(type, data, user)}
                            // ! DATALAYER
                            eventDataLayer={{
                              type: DATALAYER_TYPE.TRACK_POSITION,
                              router: router.pathname,
                              section: 'ข่าวที่คุณกำลังติดตาม',
                              position: 'ใต้:section-2',
                              data: {
                                block: '1',
                                heading: `${convertFollowing(selectedTag, { id: item?.id, nameTh: item?.nameTh, nameEng: item?.nameEng, image: item?.image })}แท็ก-${item?.nameTh}`,
                                title: item?.nameTh,
                                index: index + 1
                              }
                            }}
                            statusText={convertFollowing(selectedTag, { id: item?.id, nameTh: item?.nameTh, nameEng: item?.nameEng, image: item?.image })}
                          />
                        </div>
                      );
                    })}
                  </CarouselsLine>
                )
              ) : _.isEmpty(leftData) ? (
                loading ? (
                  'loading...'
                ) : (
                  ''
                )
              ) : (
                <>
                  <CarouselsLine infinite={false} mobileSlideShow={1} slideShow={3}>
                    {_.map(leftData.slice(0, 6), (item, index) => {
                      return (
                        <div key={index} className='card-item'>
                          {item ? (
                            <CardV
                              path={item?.link}
                              data={item}
                              description={false}
                              // ! DATALAYER
                              eventDataLayer={{
                                type: DATALAYER_TYPE.TRACK_POSITION,
                                router: router.pathname,
                                section: 'ข่าวที่คุณกำลังติดตาม',
                                position: 'ใต้:section-2',
                                data: {
                                  block: '1',
                                  heading: 'ข่าวที่คุณกำลังติดตาม',
                                  title: item?.title,
                                  index: index + 1
                                }
                              }}
                            />
                          ) : (
                            ''
                          )}
                        </div>
                      );
                    })}
                  </CarouselsLine>
                </>
              )}
            </div>
            <div className='right-wrapper'>
              {showMenu ? (
                _.isEmpty(menu) ? (
                  ''
                ) : (
                  <div className='card-member'>
                    {_.map(menu, (item, index) => {
                      return (
                        <div ref={index === 0 ? firstChildRightRef : null} key={index} className='card-item'>
                          {item ? (
                            <CardSubscribe
                              // ! DATALAYER
                              eventDataLayer={{
                                type: DATALAYER_TYPE.TRACK_POSITION,
                                router: router.pathname,
                                section: 'ข่าวที่คุณกำลังติดตาม',
                                position: 'ใต้:section-2',
                                data: {
                                  block: '2',
                                  title: user?.email,
                                  index: index + 1
                                }
                              }}
                              data={item}
                              showBlurb={false}
                              showTimeAgo={false}
                              showPublishDate={false}
                              subscribe={true}
                              user={user}
                            />
                          ) : (
                            'loading'
                          )}
                        </div>
                      );
                    })}
                  </div>
                )
              ) : _.isEmpty(rightData) ? (
                ''
              ) : (
                <div className='card-member'>
                  {_.map(rightData, (item, index) => {
                    return (
                      <div ref={index === 0 ? firstChildRightRef : null} key={index} className='card-item'>
                        {item ? (
                          <CardHSubscribe
                            data={item}
                            showBlurb={false}
                            showTimeAgo={false}
                            showPublishDate={false}
                            subscribe={false}
                            session={user}
                            // ! DATALAYER
                            eventDataLayer={{
                              type: DATALAYER_TYPE.TRACK_POSITION,
                              router: router.pathname,
                              section: 'ข่าวที่คุณกำลังติดตาม',
                              position: 'ใต้:section-2',
                              data: {
                                block: '2',
                                heading: 'ข่าวที่คุณกำลังติดตาม',
                                title: item?.title,
                                index: index + 1
                              }
                            }}
                          />
                        ) : (
                          ''
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </SectionWrapper>
    </>
  );
};

const SectionWrapper = styled.section`
  position: relative;
  background-blend-mode: overlay, normal;
  background: linear-gradient(rgb(0, 0, 99) 0%, rgb(0, 0, 99) 75%, #bd2226 75%, #bd2226 76%, rgb(0, 0, 99) 70%, rgb(0, 0, 99) 80%, rgb(255, 255, 255) 80%);
  padding: 30px 15px;
  margin: auto;
  width: 100%;
  /* margin: 30px 0 60px 0; */
  @media (max-width: 768px) {
    background: linear-gradient(rgb(0, 0, 99) 0%, rgb(0, 0, 99) 31%, #bd2226 31%, #bd2226 32%, rgb(0, 0, 99) 30%, rgb(0, 0, 99) 40%, rgb(255, 255, 255) 40%);

    /* background: linear-gradient(#000063 0%, #000063 30%, #fff 30%); */
  }
  .container {
    padding: 0px 15px;

    .member-wrapper {
      display: grid;
      grid-gap: 15px;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      grid-template-areas: 'left left left right right';
      @media (max-width: 768px) {
        grid-template-areas:
          'left left left left left'
          'right right right right right';
      }
      .left-wrapper {
        grid-area: left;
        background: #fff;
        padding: 10px;
        border-radius: 10px;
        min-height: 300px;
        .card-following {
          margin: 5px 8px 10px;
        }
        .carousel-wrapper {
          position: relative;
          top: 50%;
          transform: translateY(-50%);
          display: block !important;
          margin: auto;
        }
      }
    }
    .right-wrapper {
      grid-area: right;
      background: #fff;
      padding: 10px;
      border-radius: 10px;
      @media (max-width: 768px) {
        padding: 10px 5px;
      }
    }
  }

  .slick-slider {
    .slick-track {
      margin: 0;
    }
    .slick-dots {
      bottom: 0;
    }
  }
  .card-member {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 2px;

    width: 100%;
    gap: 15px;
    overflow-y: auto;
    max-height: ${({ rightHeight }) => rightHeight}px;
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
      background-color: #f5f5f5;
      border-radius: 15px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 15px;
      background-color: rgba(247, 247, 247, 0.9);
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 15px;
      background-color: rgb(2, 63, 134, 0.3);
    }

    .follow-item {
      width: 100%;
      .card-following {
        width: 200px;
        @media (max-width: 767px) {
          width: 150px;
        }
      }
    }
  }
  @media (max-width: 1024px) {
    padding: 30px 0;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 15px;
  padding: 15px 0px;
  p {
    margin: 0;
    width: 100%;
    font-size: 22px;
    font-weight: 900;
    line-height: 1.45;
    text-transform: uppercase;
    color: #fff;
    @media (max-width: 767px) {
      font-size: 20px;
    }
    @media (max-width: 380px) {
      font-size: 16px;
    }
  }
  svg {
    color: #fff;
    margin-right: 10px;
  }
  .icon-tool {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 3ms ease-in;
    svg {
      color: #fff;
      padding: 3px;
      margin-right: 0px;
      transition: all 0.3ms ease-in;
    }
    span {
      color: #fff;
      font-weight: 400;
      font-size: 16px;
      padding-right: 5px;
      margin-bottom: -5px;
      @media (max-width: 767px) {
        display: none;
      }
    }
    &:hover {
      svg {
        color: rgba(255, 255, 255, 0.6);
      }
    }
  }
`;

export default SectionMember;
